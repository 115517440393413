import request from "@/plugin/request";

export const getTaskList = (queryParams, page = {
    size: 100,
    current: 1
}) => request.post('/task/getBuyerTaskList', {
    page, queryParams
})
export const getSellerTaskList = (queryParams, page = {
    size: 100,
    current: 1
}) => request.post('/task/sellerGetList', {
    page, queryParams
})
export const exportSellerList = (queryParams) => request.post('/task/sellerExport', {
    queryParams,
    page: {
        size: 100,
        current: 1
    }
})

export const exportSellerListByIds = (ids) => request.get('/task/sellerExportByIds?ids=' + ids)

export const exportAlipayByIds = (ids) => request.get('/task/exportAlipayByIds?ids=' + ids)

export const saveRemark = (item) => request.post('/task/updateRemark', item)

export const cancelTrial = (id) => request.get('/task/buyerCancelApply?taskId=' + id)

export const complain = (data) => request.post('/task/complain', data)

export const cancelComplain = (id) => request.post('/task/cancelComplain?taskId=' + id)

export const complainToPlatform = (id) => request.post('/task/complain2Platform?taskId=' + id)

export const currentFlowList = (id) => request.post('/task/getCurrentFlowList', { taskId: id })

export const taskDetail = (id) => request.get('/task/get?id=' + id)

export const updateFlow = (data) => request.post('/task/updateFlow', data)

export const statisBuyerTaskNum = () => request.get('/task/statisBuyerTaskNum')

export const statisSellerTaskNum = () => request.get('/task/statisSellerTaskNum')

export const addRecycleTime = (ids, hours) => request.post('/task/batchAddRecycleTime', { ids, hours })

export const addRushFee = (ids, fee) => request.post('/task/batchAddRushFee', { ids, fee })

export const recycle = (ids) => request.get('/task/batchRecycle?ids=' + ids)

export const applyAudit = (id, status) => request.post('/task/applyAudit', { taskId: id, auditStatus: status })

export const confirmActualPrice = (id) => request.get('/task/confirmActualPrice?taskId=' + id)

export const setRefunded = (ids) => request.get('/task/setRefunded?taskIds=' + ids)

export const setFinished = (ids) => request.get('/task/setFinished?taskId=' + ids)

export const setRefundAccountType = (id, type) => request.get('/task/setRefundAccountType', {
    taskId: id,
    payMethod: type
})

export const comment = (id, role, level) => request.post('/task/comment', {
    taskId: id,
    userRole: role,
    commentLevel: level
})

export const calcPrice = (data) => request.post('/task/calcPrice', data)

export const publish = (data) => request.post('/task/add', data)

export const getLatestId = (data) => request.get('/task/getLatestId')

export const getSellerTemplateList = (queryParams = {}, page = {
    size: 100,
    current: 1
}) => request.post('/task/getSellerTemplateList', {
    page, queryParams
})

export const deleteTemplate = (id) => request.get('/task/deleteTemplate?id=' + id)

export const updateTemplateName = (id, templateName) => request.post('/task/updateTemplateName', {
    taskId: id,
    templateName
})
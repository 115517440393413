<template>
  <div class="process-main">
    <span v-if="detail.appointDays > 0">请于 {{ time }} 后，拍下宝贝</span>
    【单价：
    <span class="text-red-500">{{ detail.goodsPrice }}</span>
    ，购买数量：
    <span class="text-red-500">{{ detail.goodsNum }}</span>
    ，规格：
    <span class="text-red-500">{{ detail.spec }}</span>
    】
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "",
  props: {
    stepData: {
      type: Object,
    },
    detail: Object,
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        console.log(newValue, oldValue);
        this.$emit("update-done", true);
      },
      deep: true,
    },
  },
  computed: {
    time() {
      const date = new Date(this.detail.searchTime);
      const time = date.getTime() + this.detail.appointDays * 1000 * 3600 * 24;
      return moment(time).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  mounted() {
    this.$emit("update-done", true);
  },
  data() {
    return {
      isDone: false,
      data: {},
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped></style>

<template>
  <div class="process-main">
    <div class="process-b-item text-red-500">请根据以下要求，在淘宝中找到对应的商品</div>
    <div class="process-b-item">
      搜索类型：
      <span class="text-red-500">{{ stepData.searchMethod == "NORMAL_SEARCH" ? "普通搜索" : "直通车搜索" }}</span>
    </div>
    <div class="process-b-item">
      关键词：
      <span class="text-red-500 mr-4">{{ stepData.searchKey }}</span>
      <el-button round size="small" type="success" @click="copyKey">复制关键词</el-button>
    </div>
    <div class="process-b-item">店铺名：{{ stepData.shopName }}</div>
    <div class="process-b-item">宝贝规格：{{ stepData.spec }}</div>
    <div class="process-b-item">
      宝贝主图
      <div class="process-b-item-content">
        <el-image
          class="mr-4"
          style="width: 100px; height: 100px"
          :src="img"
          :key="img"
          v-for="img in stepData.imgs.split(',')"
          fit="cover"
        ></el-image>
      </div>
    </div>
    <div class="process-b-item" v-if="!stepData.noScreenShotMode">
      1.请上传带有关键词搜索截图
      <div class="process-b-item-content">
        <uploader v-model="data.image1" :type="'dotask'"></uploader>
      </div>
    </div>
    <div class="process-b-item" v-if="!stepData.noScreenShotMode">
      打开查找到的宝贝，复制网址或店铺
      <el-input v-model="verifyUrl" size="small" style="max-width: 300px;"></el-input>
      <el-button round class="ml-4" size="small" type="success" @click="matchTest" v-if="verifyTxt == '验证宝贝'">{{ verifyTxt }}</el-button>
      <el-button round class="ml-4" size="small" type="primary" @click="matchTest" v-if="verifyTxt == '匹配'">{{ verifyTxt }}</el-button>
      <el-button round class="ml-4" size="small" type="info" @click="matchTest" v-if="verifyTxt == '不匹配'">{{ verifyTxt }}</el-button>
    </div>

    <div class="process-b-item" v-if="!stepData.noScreenShotMode">
      3.请上传宝贝顶图、底图
      <div class="process-b-item-content process-b-item-content_flex gap-x-4">
        <uploader v-model="data.image2" :type="'dotask'"></uploader>
        <uploader v-model="data.image3" :type="'dotask'"></uploader>
        <!-- <uploader v-model="data.image4" :type="'dotask'"></uploader> -->
      </div>
    </div>
    <div class="process-b-item" v-if="stepData.noScreenShotMode">
      请浏览宝贝主图以及附图，至少十秒钟以上。注意：是主副图十秒。
    </div>
  </div>
</template>

<script>
import uploader from "@/components/uploader";
export default {
  components: {
    uploader,
  },
  props: {
    stepData: {
      type: Object,
    },
    detail: Object,
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        if (!this.stepData.noScreenShotMode) {
          // 四个都要不为 ''
          let done = true;
          Object.keys(newValue).forEach((key) => {
            if (newValue[key] === "") {
              done = false;
              return;
            }
          });
          if (done) {
            this.$emit("update-done", {
              imgs: newValue.image1,
              extraImgs: newValue.image2 + "," + newValue.image3,
            });
          }
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.stepData.noScreenShotMode) {
      setTimeout(() => {
        this.$emit("update-done", true);
      }, 0);
    } else {
      this.data.image1 = this.stepData.searchImgs || "";
      const arr = this.stepData.searchGoodsImgs.split(",");
      if (arr.length == 2) {
        this.data.image2 = arr[0];
        this.data.image3 = arr[1];
        // this.data.image4 = arr[2];
      }
    }

    this.loading = false;
  },
  data() {
    return {
      loading: true,
      verifyUrl: '',
      verifyTxt: '验证宝贝',
      data: {
        image1: "",
        image2: "",
        image3: "",
        // image4: "",
      },
    };
  },
  methods: {
    matchTest() {
      if (this.verifyUrl === this.stepData.link) {
        this.verifyTxt = '匹配'
      } else {
        this.verifyTxt = '不匹配'
      }
    },
    copyKey() {
      this.$copyText(this.stepData.searchKey).then(
        () => {
          this.$message.success("已复制");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
    },
    checkLink() {
      this.$copyText(this.stepData.link).then(
        () => {
          this.$message.success("已复制");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
    },
    handleAvatarSuccess(res, file) {
      this.image1 = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
.process-main {
  .process-b-item {
    padding: 0.5rem 0;

    .process-b-item-content {
      margin-top: 0.5rem;
    }
    .process-b-item-content_flex {
      display: flex;
      align-items: center;
    }
  }
}

.avatar-uploader /deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>

<template>
  <div class="process-main">
    <div class="process-g-item">
      1.每家至少停留
      <span class="text-red-500">【1 分钟】</span>
      <span v-if="stepData.compareNum !== 0">，每个货的商品都要截图。</span>
    </div>
    <div class="process-g-item">
      2.请确认使用
      <span class="text-red-500">【{{ detail.buyAccount ? detail.buyAccount.account : "" }}】</span>
      账号登录。
    </div>
    <div class="process-g-item">
      3.点击搜索粘贴指定的关键词
      <span class="text-red-500 mr-4">【{{ stepData.searchKey }}】</span>
      <el-button type="success" size="small" round @click="copyText"> 复制关键词 </el-button>
    </div>
    <div class="process-g-item" v-if="!stepData.noScreenShotMode && stepData.compareNum !== 0">
      4.请上传 {{ stepData.compareNum }} 张不同商家的宝贝比货截图。
    </div>
    <div class="process-g-item flex flex-row gap-x-3" v-if="!stepData.noScreenShotMode && stepData.compareNum !== 0">
      <uploader v-model="image[i - 1]" v-for="i in stepData.compareNum" :key="i" :type="'dotask'"/>
    </div>
    <!-- <div class="process-c-item">上传时间：2023-01-01 10:00:00</div> -->
  </div>
</template>

<script>
import uploader from "@/components/uploader";
export default {
  components: {
    uploader,
  },
  props: {
    stepData: {
      type: Object,
    },
    detail: Object,
  },
  watch: {
    image: {
      handler(newValue, oldValue) {
        if (newValue.length == this.stepData.compareNum) {
          if (newValue.every((item) => item != "")) {
            this.$emit("update-done", newValue.join(","));
          }
        }
      },
      deep: true,
    },
  },
  created() {
    // this.data = this.stepData
  },
  mounted() {
    if (this.stepData.compareNum == 0) {
      setTimeout(() => {
        this.$emit("update-done", true);
      }, 0);
    } else {
      this.image = this.stepData.compareImgs?.split(",") || new Array(this.stepData.compareNum).fill("");
    }
    this.loading = false;
  },
  data() {
    return {
      image: [],
      loading: true,
    };
  },
  methods: {
    copyText() {
      this.$copyText(this.stepData.searchKey).then(
        () => {
          this.$message.success("已复制");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
    },
    handleAvatarSuccess(res, file) {
      this.image = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="less" scoped>
.process-main {
  .process-g-item {
    margin-bottom: 1rem;
  }
}
.avatar-uploader /deep/.el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>

<template>
  <div class="p_main">
    <div class="action">
      <el-button type="success" size="small" @click="addDialogShow = true">新增黑名单</el-button>
      &nbsp;&nbsp;&nbsp;
      <span>添加黑名单后，发布方的任务就会被接手方禁止接手。</span>
    </div>
    <div class="table w-[100%]">
      <el-table :data="tableData" border style="width: 100%" v-loading="loading">
        <el-table-column prop="buyAccountAccount" label="用户" align="center" />
        <el-table-column prop="reason" label="拉黑原因" align="center" />
        <el-table-column prop="createTime" label="拉黑时间" align="center" width="180" />
        <el-table-column label="操作" align="center" width="100">
          <template slot-scope="scope">
            <el-button type="danger" size="small" @click="deleteFun(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="page.total"
        :current-page.sync="page.current"
      ></el-pagination>
    </div>

    <el-dialog title="新增" width="25%" :visible.sync="addDialogShow" :fullscreen="isMobile" class="dialog" center>
      <div>
        <el-form ref="formData" :model="formData" :rules="formRules" label-width="100px" :inline-message="true">
          <el-form-item label="拉黑用户名" prop="buyAccountAccount">
            <el-input class="form-input" v-model="formData.buyAccountAccount" size="small"></el-input>
          </el-form-item>

          <el-form-item label="拉黑原因" prop="reason">
            <el-input type="textarea" class="form-input" v-model="formData.reason" size="small"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="info" @click="addDialogShow = false" size="small">取消</el-button>
            <el-button class="form-btn" type="primary" @click="formSubmit('formData')" size="small">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/blacklist";
export default {
  data() {
    return {
      addDialogShow: false,
      isMobile: false,
      tableData: [],
      formData: {
        buyAccountAccount: "",
        reason: "",
      },
      formRules: {
        buyAccountAccount: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        reason: [{ required: true, message: "请输入原因", trigger: "blur" }],
      },
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      loading: false,
    };
  },
  watch: {
    "page.current"() {
      this.getList();
    },
  },
  methods: {
    deleteFun(row) {
      this.$confirm("确定要从黑名单移除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await api.del(row.id);
        this.$message({
          type: "success",
          message: "移除成功!",
        });
        this.getList();
      });
    },
    // 表单提交
    formSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await api.add(this.formData.buyAccountAccount, this.formData.reason);
          this.$message({
            type: "success",
            message: "添加成功!",
          });
          this.getList();
          this.addDialogShow = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    async getList() {
      this.loading = true;
      try {
        const {
          data: { list, page },
        } = await api.get({}, this.page);
        this.tableData = list;
        this.page = page;
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.isMobile = document.documentElement.clientWidth <= 520;
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.p_main {
  padding: 1rem;
  background-color: #fff;
  .action,
  .table {
    margin-bottom: 1rem;
  }
  .pagination {
    text-align: right;
  }
}
</style>

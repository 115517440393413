<template>
  <div id="app">
    <component :is="layout">
      <router-view v-if="isRouterAlive"></router-view>
    </component>
  </div>
</template>

<script>
import { getConfig } from "./api/config";
import { userInfo } from "./api/user";
import { unreadNotice } from "./api/notice";
export default {
  name: "App",
  components: {},
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      default_layout: "default",
      isRouterAlive: true,
      messageInterval: null,
      displayMessageList: [],
    };
  },
  mounted() {
    this.getConfig();
    this.$nextTick(() => {
      if (this.userInfo.isLogin) {
        this.refreshUserInfo();
      }
    });

    this.messageInterval = setInterval(() => {
      // console.log('----------' + JSON.stringify(this.userInfo))
      if (this.userInfo.isLogin) {
        this.getUnreadNotice();
      }
    }, 1000 * 10);
  },
  beforeDestroy() {
    clearInterval(this.messageInterval);
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || this.default_layout) + "-layout";
    },
    userInfo() {
      return this.$store.state.user;
    },
  },
  methods: {
    //通过声明reload方法，控制router-view的显示或隐藏，从而控制页面的再次加载
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    async getConfig() {
      const { data } = await getConfig();
      this.$store.commit("config/SET_CONFIG", data);
      console.log(data);
    },
    async refreshUserInfo() {
      if (this.$route.path.includes('register')) return
      const { data } = await userInfo();
      if (data == null) {
        this.$store.commit("user/LOGOUT");
        this.$router.push("/login");
        return;
      }
      this.$store.commit("user/LOGIN", data);
    },
    async getUnreadNotice() {
      const { data } = await unreadNotice();
      if (data.length > 0) {
        const message = data[0];
        if (this.displayMessageList.includes(message.id)) {
          return;
        }
        const content = message.content.length > 40 ? message.content.substring(0, 40) + "..." : message.content;
        this.displayMessageList.push(message.id);
        const notify = this.$notify({
          title: message.name,
          message: content,
          position: "bottom-right",
          type: "info",
          duration: 0,
          onClick: () => {
            this.$router.push({ path: `/user/message/${message.id}`, query: { item: message } });
            notify.close();
          },
          onClose: () => {
            this.displayMessageList = this.displayMessageList.filter((item) => item !== message.id);
          },
        });
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>

<template>
  <div class="p_main">
    <div class="action">
      <el-button type="success" size="small" @click="addDialogShow = true">新增店铺</el-button>
      &nbsp;&nbsp;&nbsp;
      <div>
        <div>大数据读抓取不到，你的信息可放心</div>
        <div>本站有许多人性化黑科技服务，也要用到正确网店名</div>
      </div>
    </div>
    <div class="table w-[100%]">
      <el-table :data="tableData" border style="width: 100%" v-loading="loading">
        <el-table-column prop="platformType" label="分类" align="center" />
        <el-table-column prop="name" label="名称" align="center" />
        <el-table-column label="审核状态" align="center" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.auditStatus == 'SUCCESS'" class="text-green-500">成功</span>
            <span v-else-if="scope.row.auditStatus == 'FAIL'" class="text-red-500">失败</span>
            <span v-else-if="scope.row.auditStatus == 'WAITING'">待处理</span>
            <span v-else>CANCELED</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="180">
          <template slot-scope="scope">
            <el-button type="primary" size="small" v-if="scope.row.status == 0" @click="statusSwitch(scope.row.id, 1)"
              >启用</el-button
            >
            <el-button type="danger" size="small" v-else @click="statusSwitch(scope.row.id, 0)">禁用</el-button>
            <el-button type="danger" size="small" @click="deleteFun(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="新增" width="25%" :visible.sync="addDialogShow" :fullscreen="isMobile" class="dialog" center>
      <div>
        <el-form ref="formData" :model="formData" :rules="formRules" label-width="100px" :inline-message="true">
          <el-form-item label="类型" prop="type">
            <el-select v-model="formData.type" size="small" style="width: 80%">
              <el-option label="天猫淘宝" value="TAO_BAO"> </el-option>
              <el-option label="拼多多" value="PDD"> </el-option>
              <el-option label="京东" value="JD"> </el-option>
              <el-option label="抖音" value="DOU_YIN"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="店铺名称" prop="name">
            <el-input class="form-input" v-model="formData.name" size="small" style="width: 80%"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="info" @click="addDialogShow = false" size="small">取消</el-button>
            <el-button class="form-btn" type="primary" @click="formSubmit('formData')" size="small">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addShop, deleteShop, getList, switchStatus } from "@/api/shop";
export default {
  data() {
    return {
      addDialogShow: false,
      isMobile: false,
      tableData: [],
      formData: {
        name: "",
        type: "",
      },
      formRules: {
        name: [{ required: true, message: "请输入店铺名称", trigger: "blur" }],
        type: [{ required: true, message: "请选择类型", trigger: "blur" }],
      },
      loading: false,
    };
  },
  methods: {
    deleteFun(row) {
      this.$confirm("确定要删除这个店铺吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await deleteShop(row.id);
        this.$message({
          type: "success",
          message: "删除成功!",
        });
        this.getList();
      });
    },
    // 表单提交
    formSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await addShop(this.formData.type, this.formData.name);
          this.$message({
            type: "success",
            message: "添加成功!",
          });
          this.getList();
          this.addDialogShow = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async getList() {
      this.loading = true;
      try {
        const {
          data: { list },
        } = await getList({
          sellerId: this.$store.state.user.id,
        });
        this.tableData = list.map((item) => {
          return {
            ...item,
            platformType:
              item.platformType === "TAO_BAO"
                ? "天猫淘宝"
                : item.platformType === "JD"
                ? "京东"
                : item.platformType === "PDD"
                ? "拼多多"
                : "抖音",
          };
        });
      } finally {
        this.loading = false;
      }
    },
    async statusSwitch(id, status) {
      await switchStatus(id, status);
      this.$message({
        type: "success",
        message: "操作成功",
      });
      this.getList();
    },
  },
  mounted() {
    this.isMobile = document.documentElement.clientWidth <= 520;
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.p_main {
  padding: 1rem;
  background-color: #fff;
  .action,
  .table {
    margin-bottom: 1rem;
  }
  .action {
    display: flex;
  }
}
</style>

<template>
  <div class="c_main">
    <el-form ref="formData" :model="formData" :rules="formRule" label-width="120px">
      <el-form-item label="无截图模式：" prop="noScreenShotMode">
        <el-radio-group v-model="formData.noScreenShotMode">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
        <span class="help">选用无截图模式更为安全！勾选此项试客将无需上传本页所勾选的截图证明</span>
      </el-form-item>
      <el-form-item label="支付方式：" prop="approvedPayMethod">
        <el-radio-group v-model="formData.approvedPayMethod">
          <el-radio label="NO_HB_NO_CREDIT">禁止花呗、禁止信用卡</el-radio>
          <el-radio label="YES_HB_NO_CREDIT">支持花呗、禁止信用卡</el-radio>
          <el-radio label="YES_HB_YES_CREDIT">支持花呗、支持信用卡</el-radio>
        </el-radio-group>
        <div class="help">默认支持储蓄支付，如果禁止花呗、信用支付请另外勾选</div>
      </el-form-item>
      <el-form-item label="设备要求：" prop="device">
        <el-radio-group v-model="formData.device">
          <el-radio label="PHONE">手机</el-radio>
          <el-radio label="PC">电脑</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="货比三家：" prop="compareNum">
        <el-radio-group v-model="formData.compareNum" @change="refreshPrice">
          <el-radio :label="1">货比1家</el-radio>
          <el-radio :label="2">货比2家</el-radio>
          <el-radio :label="3">货比3家</el-radio>
          <el-radio :label="0">不需要</el-radio>
        </el-radio-group>
        <div class="help">{{ systemConfig.TASK_HUO_BI_SAN_JIA_JIA_GE }}</div>
      </el-form-item>
      <el-form-item label="拍前聊天：" prop="needChat">
        <el-radio-group v-model="formData.needChat" @change="refreshPrice">
          <el-radio :label="1">需要</el-radio>
          <el-radio :label="0">不需要</el-radio>
        </el-radio-group>
        <span class="help">{{ systemConfig.TASK_LIAO_TIAN_JIA_GE }}</span>
      </el-form-item>

      <el-form-item label="浏览评价：" prop="needBrowseComments">
        <el-radio-group v-model="formData.needBrowseComments" @change="refreshPrice">
          <el-radio :label="1">需要</el-radio>
          <el-radio :label="0">不需要</el-radio>
        </el-radio-group>
        <span class="help">{{ systemConfig.TASK_LIU_LAN_PIN_JIA_JIA_GE }}</span>
      </el-form-item>

      <el-form-item label="加购物车：" prop="needAddCart">
        <el-radio-group v-model="formData.needAddCart" @change="refreshPrice">
          <el-radio :label="1">需要</el-radio>
          <el-radio :label="0">不需要</el-radio>
        </el-radio-group>
        <span class="help">{{ systemConfig.TASK_JIA_GOU_JIA_GE }}</span>
      </el-form-item>

      <el-form-item label="收藏宝贝：" prop="needCollect">
        <el-radio-group v-model="formData.needCollect" @change="refreshPrice">
          <el-radio :label="1">需要</el-radio>
          <el-radio :label="0">不需要</el-radio>
        </el-radio-group>
        <span class="help">{{ systemConfig.TASK_SHOU_CANG_JIA_GE }}</span>
      </el-form-item>

      <el-form-item label="预约下单：" prop="appointDays">
        <el-radio-group v-model="formData.appointDays" @change="refreshPrice">
          <el-radio :label="0">浏览后下单</el-radio>
          <el-radio :label="1">1天后下单</el-radio>
          <el-radio :label="2">2天后下单</el-radio>
          <el-radio :label="3">3天后下单</el-radio>
        </el-radio-group>
        <span class="help">{{ systemConfig.TASK_YU_YUE_XIA_DAN_JIA_GE }}</span>
      </el-form-item>

      <el-form-item label="指定晒图：" prop="needShowSpecifiedImg">
        <el-radio-group v-model="formData.needShowSpecifiedImg" @change="refreshPrice">
          <el-radio :label="1">需要</el-radio>
          <el-radio :label="0">不需要</el-radio>
        </el-radio-group>
        <span class="help">{{ systemConfig.TASK_ZHI_DING_SHAI_TU_JIA_GE }}</span>
      </el-form-item>

      <el-form-item label="晒图：" v-if="formData.needShowSpecifiedImg">
        <uploader-group v-model="formData.specifiedImgList" :type="'task'"></uploader-group>

        <span class="help">使用指定晒图建议每次只发一个任务，否则所晒图会是同一图片</span>
      </el-form-item>

      <el-form-item label="指定晒视频：" prop="needShowSpecifiedVideo">
        <el-radio-group v-model="formData.needShowSpecifiedVideo" @change="refreshPrice">
          <el-radio :label="1">需要</el-radio>
          <el-radio :label="0">不需要</el-radio>
        </el-radio-group>
        <span class="help">{{ systemConfig.TASK_ZHI_DING_SHAI_SHI_PIN_JIA_GE }}</span>
      </el-form-item>

      <el-form-item label="晒视频：" v-if="formData.needShowSpecifiedVideo">
        <uploader v-model="formData.specifiedVideo" listType="video" :type="'video'"></uploader>
        <span class="help">使用指定晒视频建议每次只发一个任务，否则所晒视频会是同一图片</span>
      </el-form-item>

      <el-form-item label="评价建议：" prop="commentPrompt">
        <el-input type="textarea" v-model="formData.commentPrompt" size="small"></el-input>
        <div class="help_block">
          不要这里加表情和特殊符号。没特殊评价要求不要写，防止试客不明白商家意图直接复制粘贴内容，造成不必要的错误好评。
        </div>
      </el-form-item>

      <el-form-item label="追评：" prop="needAddComment">
        <el-radio-group v-model="formData.needAddComment" @change="refreshPrice">
          <el-radio :label="1">需要</el-radio>
          <el-radio :label="0">不需要</el-radio>
        </el-radio-group>
        <span class="help">{{ systemConfig.TASK_ZHUI_PIN_JIA_GE }}</span>
      </el-form-item>
      <el-form-item label="追评提示：" v-if="formData.needAddComment">
        <el-input type="textarea" v-model="formData.addCommentPrompt" size="small"></el-input>
      </el-form-item>

      <el-form-item label="问大家：" props="needAnswer">
        <el-radio-group v-model="formData.needAnswer" @change="refreshPrice">
          <el-radio :label="1">需要</el-radio>
          <el-radio :label="0">不需要</el-radio>
        </el-radio-group>
        <span class="help">{{ systemConfig.TASK_HUI_DA_JIA_GE }}</span>
      </el-form-item>
      <el-form-item label="问大家提示：" v-if="formData.needAnswer">
        <el-input type="textarea" v-model="formData.answerPrompt" size="small"></el-input>
      </el-form-item>

      <el-form-item label="要求确认时间：" props="confirmDays">
        <el-radio-group v-model="formData.confirmDays">
          <el-radio :label="0">马上确认</el-radio>
          <el-radio :label="1">1天</el-radio>
          <el-radio :label="2">2天</el-radio>
          <el-radio :label="3">3天</el-radio>
          <el-radio :label="4">4天</el-radio>
          <el-radio :label="5">5天</el-radio>
          <el-radio :label="90">按物流确认</el-radio>
          <el-radio :label="91">系统自动确认</el-radio>
        </el-radio-group>
        <div class="help_block">马上确认则必须立刻发货，属于虚拟商品任务。系统自动确认，就是默认好评</div>
      </el-form-item>

      <el-form-item label="留言提醒：" prop="messagePrompt">
        <el-input type="textarea" v-model="formData.messagePrompt" size="small"></el-input>
        <div class="help_block">搜索没排名给发货地提示及其他要对试客的留言说明也可在这里分享淘口令</div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import uploader from "@/components/uploader.vue";
import uploaderGroup from "@/components/uploader-group.vue";
export default {
  props: {
    forms: Array,
  },
  components: {
    uploader,
    uploaderGroup,
  },
  data() {
    return {
      formData: {
        noScreenShotMode: 1,
        approvedPayMethod: "NO_HB_NO_CREDIT",
        device: "PHONE",
        compareNum: 0,
        needChat: 0,
        needBrowseComments: 0,
        needAddCart: 0,
        needCollect: 0,
        appointDays: 0,
        needShowSpecifiedImg: 0,
        specifiedImgList: [],
        specifiedImgs: '',
        needShowSpecifiedVideo: 0,
        specifiedVideo: "",
        commentPrompt: "",
        needAddComment: 0,
        addCommentPrompt: "",
        confirmDays: 90,
        messagePrompt: "",
        needAnswer: 0,
        answerPrompt: "",
      },
      formRule: {
        noScreenShotMode: [{ required: true, message: "请选择是否允许截图", trigger: "change" }],
        approvedPayMethod: [{ required: true, message: "请选择付款方式", trigger: "change" }],
        device: [{ required: true, message: "请选择设备", trigger: "change" }],
        compareNum: [{ required: true, message: "请输入比价数量", trigger: "change" }],
        needChat: [{ required: true, message: "请选择是否需要聊天", trigger: "change" }],
        needBrowseComments: [{ required: true, message: "请选择是否需要浏览评论", trigger: "change" }],
        needAddCart: [{ required: true, message: "请选择是否需要加购物车", trigger: "change" }],
        needCollect: [{ required: true, message: "请选择是否需要收藏", trigger: "change" }],
        appointDays: [{ required: true, message: "请选择指定天数", trigger: "change" }],
        needShowSpecifiedImg: [{ required: true, message: "请选择是否需要晒图", trigger: "change" }],
        needShowSpecifiedVideo: [{ required: true, message: "请选择是否需要晒视频", trigger: "change" }],
        needAddComment: [{ required: true, message: "请选择是否需要追评", trigger: "change" }],
        confirmDays: [{ required: true, message: "请选择确认时间", trigger: "change" }],
        needAnswer: [{ required: true, message: "请选择是否需要回答问题", trigger: "change" }],
      },
    };
  },
  mounted() {
    if (Object.keys(this.forms[1].formData).length > 0) {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = this.forms[1].formData[key];
      });
      this.formData.specifiedImgList = this.formData.specifiedImgs ? this.formData.specifiedImgs.split(",") : [];
    }
  },
  computed: {
    systemConfig() {
      return this.$store.state.config.config;
    },
    // priceConfig() {
    //   return this.$store.state.config.priceConfig;
    // },
  },
  methods: {
    // 表单提交，父组件访问此方法，如果表单验证成功返回表单数据，否则返回null
    async submitForm(formName) {
      if (!formName) formName = "formData";
      const result = await this.$refs[formName].validate();
      if (result) {
        return { ...this.formData, specifiedImgs: this.formData.specifiedImgList.join(",") };
      } else {
        return false;
      }
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    refreshPrice() {
      this.$emit("refreshPrice", { ...this.formData, specifiedImgs: this.formData.specifiedImgList.join(",") });
    },
  },
};
</script>

<style lang="less" scoped>
.c_main {
  .form-input {
    width: 200px;
  }

  .help {
    padding: 0 1rem;
    color: #999;
  }
  .help_block {
    color: #999;
  }

  .avatar-uploader /deep/.el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }

  .video_p {
    width: 300px;
    max-height: 300px;
  }
}

@media (max-width: 520px) {
  .c_main {
    .help {
      display: block;
      padding: 0;
    }

    .video_p {
      width: 100%;
      max-height: 300px;
    }

    .el-radio {
      margin-bottom: 15px;
    }
  }
}
</style>

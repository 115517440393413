<template>
  <div class="bg-white p-4 w-[100%] <sm:(px-0 py-0)">
    <div class="sm:hidden bg-gray-200 p-4 text-12px text-center">
      温馨提示: 请勿修改付款金额付款说明<b>否则不返数据</b>
    </div>
    <div
      class="bg-orange-200 py-2 border-bottom-2 border-orange-400 flex flex-col px-4 <sm:(bg-white px-0 border-none)"
    >
      <div class="flex items-center <sm:(border-bottom-1)">
        <span class="text-lg font-bold text-gray-600 w-90px <sm:(ml-4)">付款金额：</span>
        <span class="mr-4 text-lg text-orange-600 overflow-ellipsis">￥{{ price }}</span>
        <el-button class="<sm:(ml-auto mr-2)" type="text" @click="copyText(price)">【复制】</el-button>
      </div>
      <div class="flex items-center <sm:(border-bottom-1)" v-if="openTabs">
        <span class="text-lg font-bold text-gray-600 w-90px <sm:(ml-4)">收款人：</span>
        <span class="mr-4 text-lg text-orange-600 overflow-ellipsis">{{systemConfig.ALIPAY_ACCOUNT}}</span><span>({{systemConfig.ALIPAY_REAL_NAME}})</span>
        <el-button class="<sm:(ml-auto mr-2)" type="text" @click="copyText(systemConfig.ALIPAY_ACCOUNT)">【复制】</el-button>
      </div>
      <div class="flex items-center" v-if="openTabs">
        <span class="text-lg font-bold text-gray-600 w-90px <sm:(ml-4)">付款说明：</span>
        <span class="mr-4 text-lg text-orange-600 overflow-ellipsis">{{ ordersId }}</span>
        <el-button class="<sm:(ml-auto mr-2)" type="text" @click="copyText(ordersId)">【复制】</el-button>
      </div>
    </div>
    <div
      @click="openTabs = !openTabs"
      class="bg-orange-400 w-100px text-white ml-auto mr-10 text-center py-1 <sm:hidden"
    >
      <i class="el-icon-arrow-up mr-2" v-if="openTabs"></i>
      <i class="el-icon-arrow-down mr-2" v-else></i>
      <span>转账信息</span>
    </div>
    <div class="mt-10 border-blue-500 border-3 p-10 flex flex-row <sm:hidden">
      <div class="flex flex-col">
        <div class="text-orange-500 font-bold mb-1">
          充值流程：确认充值账号后，打开手机支付宝扫描二维码向本站支付宝转账
        </div>
        <div class="progress">1.首先打开手机支付宝钱包</div>
        <div class="progress">2.扫描本站支付宝二维码,并<b>选择转账功能</b></div>
        <div class="progress">3.付款金额填写：{{ price }}</div>
        <div class="progress">4.付款说明填写：{{ ordersId }}</div>
        <div class="progress">温馨提示: 请勿修改付款金额付款说明<b>否则不返数据</b></div>
        <div class="progress">到账时间: 付款成功后,<b>耐心等待10秒钟.</b></div>
        <div class="progress font-bold">注意事项：</div>
        <div class="progress font-bold">1.请正确填写付款说明,否则无法自动到账.</div>
        <div class="progress font-bold">2.本站支付宝账户会不定期更换,每次充值前请务必核对支付宝账号</div>
      </div>
      <div class="items-center justify-center flex flex-grow flex-col">
        <div class="w-250px h-250px border-10px border-orange-500 rounded-40px p-2 overflow-hidden">
          <img :src="systemConfig.ALIPAY_QRCODE" class="h-full" style="width: 100%; height: 100%;"/>
        </div>
        <div class="text-orange-500 bg-white px-3 text-20px" style="margin-top: -15px">{{systemConfig.ALIPAY_REAL_NAME}}</div>
      </div>
    </div>
    <div class="sm:hidden p-4 bg-gray-200">
      <div>付款说明:</div>
      <div>1.首先请登录支付宝APP</div>
      <div>2.向本站支付宝账号：{{ systemConfig.ALIPAY_ACCOUNT }}</div>
      <div>3.付款说明(备注)请填写: 9626</div>
      <div class="border-bottom-1 pb-2 border-gray-300">
        温馨提示: 请勿修改付款金额付款说明否则...<br />
        到账时间: 付款成功后, <b>耐心等待10秒钟</b>
      </div>
      <div class="pt-2">注意事项:</div>
      <div class="font-bold">1.请正确填写付款说明,否则无法自动到账</div>
      <div class="font-bold">2.本站支付宝账户会不定期更换，每次充值</div>
    </div>

    <el-dialog title="注意事项" :visible.sync="dialogVisible" width="30%">
      <img src="@/assets/image/paynotice.png" fit="contain" @click="dialogVisible = false" />
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return { openTabs: true, dialogVisible: true, ordersId: '', price: '', };
  },
  computed: {
    systemConfig() {
      return this.$store.state.config.config;
    },
  },
  mounted() {
    if (window.innerWidth < 768) {
      this.dialogVisible = false;
    }
    this.price = this.$route.query.price
    this.ordersId = this.$route.query.ordersId
  },
  methods: {
    copyText(text) {
      const input = document.createElement("input");
      input.setAttribute("readonly", "readonly");
      input.setAttribute("value", text);
      document.body.appendChild(input);
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
        this.$message.success("复制成功");
      }
      document.body.removeChild(input);
    },
  },
};
</script>

<style lang="less" scoped>
.progress {
  color: rgb(249, 115, 22);
  padding: 8px 4px;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
  }
}
</style>

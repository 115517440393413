<template>
  <div class="process-main">
    <div class="process-m-item">1.请不要使用花呗及其他信用支付方式，否则该任务将违规处理</div>
    <div class="process-m-item">商家发布的任务价格：{{ stepData.goodsFee?.toFixed(2) }}</div>
    <div class="process-m-item">若与实际付款价格不一致，请填写实际付款价格，提交订单，然后联系客服</div>
    <div class="process-m-item">
      2.请输入<span class="text-red-500">【实际付款价格】</span>：
      <el-input size="small" style="width: 200px" v-model="stepData.actualGoodsFee" />
    </div>
    <div class="process-m-item">
      3.请输入<span class="text-red-500">【宝贝订单编号】</span>：
      <el-input size="small" style="width: 200px" v-model="stepData.ordersId" />
    </div>
    <div class="process-m-item">
      4.请点击 &nbsp;
      <el-button type="danger" size="small" round @click="confirm" :disabled="isDone" :loading="loading">
        确定
      </el-button>
    </div>
  </div>
</template>

<script>
import { updateFlow } from "@/api/task";

export default {
  name: "",
  props: {
    stepData: {
      type: Object,
    },
  },
  created() {
    // this.data = this.stepData
  },
  data() {
    return {
      isDone: false,
    };
  },
  methods: {
    confirm() {
      if (this.stepData.actualGoodsFee == "") {
        this.$message.error("请输入实际付款价格");
        return;
      }
      if (this.stepData.ordersId == "") {
        this.$message.error("请输入宝贝订单编号");
        return;
      }

      this.$confirm(`确认实际付款金额为【${this.stepData.actualGoodsFee}】，订单编号为【${this.stepData.ordersId}】？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        this.loading = true;
        try {
          await updateFlow({
            taskId: this.stepData.id,
            flowCode: "wan_cheng_fu_kuan",
            actualPrice: this.stepData.actualGoodsFee,
            ordersId: this.stepData.ordersId,
          });
        } catch (error) {
          this.loading = false;
          return;
        }
        this.$message.success("成功");
        this.isDone = true;
        this.loading = false;

        this.$emit("update-done", { actualPrice: this.stepData.actualGoodsFee, ordersId: this.stepData.ordersId });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.process-main {
  .process-m-item {
    margin-bottom: 0.5rem;
  }
}
</style>

<template>
  <div>
    <div class="status-fixed">
      <div class="v-container">
        <div class="status-bar">
          <div
            class="status-item"
            :class="{ 'status-item-active': currentStatusIndex == index }"
            v-for="(item, index) in tabList"
            @click="currentStatusIndex = index"
          >
            <div class="status-item_text">
              {{ item.label }}
              <el-badge style="line-height: 100%" :value="statis[item.key]" v-if="statis[item.key]" />
            </div>
          </div>
          <div
            :class="currentStatusIndex == 7 ? 'status-item status-item-active' : 'status-item'"
            @click="currentStatusIndex = 7"
          >
            <div class="status-item_text">全部任务</div>
          </div>
        </div>
      </div>
    </div>

    <div class="v-container">
      <div class="p_main">
        <div class="filter">
          <div class="flex flex-row" style="flex-wrap: wrap; row-gap: 5px;">
            <el-button class="form-btn" type="success" size="large" @click="toNewTrial">发布新试用</el-button>
            <div class="flex-grow"></div>
            <el-button
              class="form-btn"
              type="primary"
              plain
              @click="selectRecord = recordList.map((r) => r.id)"
              size="small"
              >全选</el-button
            >
            <el-button class="form-btn" type="info" plain @click="selectRecord = []" size="small">取消选择</el-button>
            <el-button class="form-btn" type="primary" size="small" @click="addRecycleTime">批量增加时间</el-button>
            <el-button class="form-btn" type="primary" size="small" @click="addRushFee">批量追加奖励</el-button>
            <el-button class="form-btn" type="primary" size="small" @click="setRefunded">批量已返款</el-button>
            <el-button
              class="form-btn"
              type="danger"
              size="small"
              @click="recycle"
              v-if="currentStatusIndex == 0 || currentStatusIndex == 1 || currentStatusIndex == 2"
              >批量回收试用</el-button
            >
            <el-button class="form-btn" type="primary" size="small" @click="exportListByIds" :loading="exporting"
              >批量导出任务</el-button
            >
            <el-button
              class="form-btn"
              type="primary"
              size="small"
              @click="exportAlipayByIds"
              :loading="exportingAlipay"
              >批量导出支付宝</el-button
            >
          </div>
        </div>
        <div class="filter">
          <el-form :model="filterData" class="form">
            <el-form-item class="form-item" label="平台类型">
              <el-select class="form-input" size="small" v-model="filterData.platform" placeholder="平台类型">
                <el-option v-for="item in platformOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item class="form-item" label="试用编号">
              <el-input class="form-input" v-model="filterData.no" size="small" placeholder="试用编号"> </el-input>
            </el-form-item>

            <el-form-item class="form-item" label="搜索条件">
              <el-select class="form-input" size="small" v-model="filterData.condition" placeholder="搜索条件">
                <el-option v-for="item in conditionOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item class="form-item" label="搜索内容">
              <el-input class="form-input" v-model="filterData.value" size="small" placeholder="搜索值"> </el-input>
            </el-form-item>

            <el-form-item class="form-item">
              <el-date-picker
                class="form-input"
                style="width: 270px"
                size="small"
                v-model="filterData.date"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item>

            <el-form-item class="form-item" label="返款状态">
              <el-select class="form-input" size="small" v-model="filterData.isRefunded" placeholder="">
                <el-option
                  v-for="item in refundStatusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <!-- <el-form-item class="form-item" label="商品分类">
              <el-select class="form-input" size="small" v-model="filterData.category" placeholder="商品分类">
                <el-option v-for="item in categoryOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </el-form-item> -->

            <el-form-item class="form-item">
              <el-button class="form-btn" type="primary" @click="formSubmit" size="small">搜索</el-button>
              <el-button class="form-btn" type="info" @click="resetForm" size="small">重置</el-button>
            </el-form-item>
            <!-- <el-form-item class="form-item">
              <el-button class="form-btn" type="primary" @click="selectRecord = recordList.map(r => r.id)" size="small">全选</el-button>
              <el-button class="form-btn" type="info" @click="selectRecord = []" size="small">取消选择</el-button>
            </el-form-item> -->
          </el-form>
        </div>

        <div class="info">
          <div class="info-item">
            <div>
              {{ systemConfig.TASK_FA_DAN_TI_SHI }}
            </div>
          </div>
        </div>

        <div class="trial-list">
          <TrialItem
            v-for="item in recordList"
            :key="item.id"
            :data="item"
            @refresh="updateData"
            :selected="selectRecord.includes(item.id)"
            @switchChecked="
              selectRecord.includes(item.id)
                ? selectRecord.splice(selectRecord.indexOf(item.id), 1)
                : selectRecord.push(item.id)
            "
          />
        </div>

        <div class="trial-statistics">
          <div>统计：试用数量：{{ totalNum }}个</div>
          <div>任务总奖励：{{ totalPrice | priceFormat }}元</div>
        </div>
        <div class="pagination float-right">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="page.total"
            :page-size="page.size"
            :current-page.sync="page.current"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TrialItem from "@/components/seller/TrialItem.vue";
import {
  exportSellerList,
  exportSellerListByIds,
  exportAlipayByIds,
  statisSellerTaskNum,
  getSellerTaskList,
  recycle,
  addRecycleTime,
  addRushFee,
  setRefunded,
} from "@/api/task";
import { goodsCateList } from "@/api/goods";
import { baseURL } from "@/plugin/axios";
import { withdrawal, loginUserInfo } from "@/api/user";
import moment from "moment";
export default {
  components: {
    TrialItem,
  },
  data() {
    return {
      currentStatusIndex: 0,
      filterData: {
        platform: "",
        no: "",
        category: "",
        condition: null,
        value: "",
        date: null,
        isRefunded: "",
      },
      platformOptions: [
        { value: "", label: "全部" },
        { value: "TAO_BAO", label: "淘宝/天猫" },
        { value: "PDD", label: "拼多多" },
        { value: "JD", label: "京东" },
        { value: "DOU_YIN", label: "抖音" },
      ],
      refundStatusOptions: [
        { value: "", label: "全部" },
        { value: "1", label: "已返款" },
        { value: "0", label: "未返款" },
      ],
      conditionOptions: [
        { value: "shopName", label: "店铺" },
        { value: "buyAccountAccount", label: "买号" },
        { value: "ordersId", label: "订单号" },
        { value: "sellerQQ", label: "商家 QQ" },
        { value: "buyerQQ", label: "试客 QQ" },
      ],
      categoryOptions: [],
      statis: {},
      tabList: [
        { label: "待申请", key: "WAITING_APPLY" },
        { label: "未审核", key: "WAITING_AUDIT" },
        { label: "已申请", key: "TOOK" },
        { label: "已下单", key: "ORDERED" },
        { label: "已确认收货", key: "GOODS_RECEIVED" },
        { label: "试用完成", key: "FINISHED" },
        { label: "投诉中", key: "COMPLAIN" },
      ],
      recordList: [],
      selectRecord: [],
      exporting: false,
      exportingAlipay: false,
      page: {
        current: 1,
        size: 5,
        total: 0,
      },
      loading: false,
    };
  },
  async mounted() {
    // 商家首页
    const urlParams = new URLSearchParams(window.location.search);
    var token = urlParams.get('token')
    var userRole = urlParams.get('userRole')
    console.log('token ' + token)
    if (token != undefined && token != '') {
      this.userInfo.token = token
      const { data } = await loginUserInfo();
      this.$store.commit("user/LOGIN", data)
    }
    this.updateData();
    this.loadCategory();
  },
  watch: {
    currentStatusIndex() {
      this.recordList = [];
      const originPage = this.page;
      this.page = {
        current: 1,
        size: 5,
      };
      this.selectRecord = [];

      this.updateData(false);
    },
    "page.current"(val, oldVal) {
      if (val == null || oldVal == null) return;
      this.updateData(true);
    },
  },
  computed: {
    systemConfig() {
      return this.$store.state.config.config;
    },
    userInfo() {
      return this.$store.state.user;
    },
    totalNum() {
      return this.recordList.length;
    },
    totalPrice() {
      let total = 0;
      this.recordList.forEach((item) => {
        total += item.buyerCommissionFee;
      });
      return total;
    },
  },
  methods: {
    // 表单提交
    formSubmit() {
      this.recordList = [];
      this.page = {
        current: 1,
        size: 5,
      };
      this.selectRecord = [];
      this.updateData();
    },
    // 重置表单
    resetForm() {
      this.filterData = {
        platform: "",
        no: "",
        condition: null,
        category: "",
        value: "",
        date: null,
      };
    },
    getParams() {
      const status = this.currentStatusIndex == 7 ? undefined : this.tabList[this.currentStatusIndex].key;
      const params = {
        status,
        id: this.filterData.no == "" ? undefined : this.filterData.no,
        platformType: this.filterData.platform == "" ? undefined : this.filterData.platform,
        cate1Id: this.filterData.category == "" ? undefined : this.filterData.category,
        queryStartTime:
          this.filterData.date == null || this.filterData.date.length == 0
            ? undefined
            : moment(this.filterData.date[0]).format("YYYY-MM-DD"),
        queryEndTime:
          this.filterData.date == null || this.filterData.date.length == 0
            ? undefined
            : moment(this.filterData.date[1]).format("YYYY-MM-DD"),
      };
      params[this.filterData.condition] = this.filterData.value == "" ? undefined : this.filterData.value;

      return params;
    },
    async loadCategory() {
      const {
        data: { list },
      } = await goodsCateList();
      this.categoryOptions = list.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    },
    async updateData(noStatis = false) {
      this.loading = true;
      try {
        const {
          data: { list: recordList, page },
        } = await getSellerTaskList(this.getParams(), this.page);
        this.recordList = recordList;
        this.page = page;
      } finally {
        this.loading = false;
      }

      if (!noStatis) {
        this.getStatis();
      }
    },
    async getStatis() {
      const { data } = await statisSellerTaskNum();
      // [{status, num}] 转为对象
      const statis = {};
      data.forEach((item) => {
        statis[item.status] = item.num;
      });
      this.statis = statis;
    },
    addRecycleTime() {
      if (this.selectRecord.length == 0) {
        this.$message.error("请先选择要延长的任务");
        return;
      }
      const ids = this.selectRecord.join(",");
      this.$prompt("请输入延长时间（小时）", "提示", {
        showCancelButton: true,
        inputType: "number",
        inputPlaceholder: "请输入延长时间",
        inputValidator: (value) => {
          if (!value) {
            return "请输入延长时间";
          }
        },
      }).then(async ({ value }) => {
        await addRecycleTime(ids, value);
        this.$message.success("延长成功");
        this.selectRecord = [];
        this.updateData();
      });
    },
    addRushFee() {
      if (this.selectRecord.length == 0) {
        this.$message.error("请先选择要追加的任务");
        return;
      }
      const ids = this.selectRecord.join(",");
      this.$prompt("请输入追加费用", "提示", {
        showCancelButton: true,
        inputType: "number",
        inputPlaceholder: "请输入追加费用",
        inputValidator: (value) => {
          if (!value) {
            return "请输入追加费用";
          }
        },
      }).then(async ({ value }) => {
        await addRushFee(ids, value);
        this.$message.success("追加成功");
        this.selectRecord = [];
        this.updateData();
      });
    },
    setRefunded() {
      if (this.selectRecord.length == 0) {
        this.$message.error("请先选择要回收的任务");
        return;
      }
      const ids = this.selectRecord.join(",");
      this.$confirm("确定设置已返款吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await setRefunded(ids);
        this.$message.success("成功");
        this.updateData();
        this.selectRecord = [];
      });
    },
    recycle() {
      if (this.selectRecord.length == 0) {
        this.$message.error("请先选择要回收的任务");
        return;
      }
      const ids = this.selectRecord.join(",");
      this.$confirm("确定回收吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await recycle(ids);
        this.$message.success("成功");
        this.updateData();
        this.selectRecord = [];
      });
    },
    async exportList() {
      this.exporting = true;
      try {
        const { data } = await exportSellerList(this.getParams());
        // 地址为 域名+'static' + data
        const address = baseURL + "/static" + data;
        const dom = document.createElement("a");
        dom.href = address;
        dom.download = data.substring(1);
        dom.click();
        dom.remove();
      } finally {
        this.exporting = false;
      }
    },
    async exportListByIds() {
      if (this.selectRecord.length == 0) {
        this.$message.error("请先选择要导出的任务");
        return;
      }
      const ids = this.selectRecord.join(",");
      this.exporting = true;
      try {
        const { data } = await exportSellerListByIds(ids);
        this.selectRecord = [];
        // 地址为 域名+'static' + data
        const address = baseURL + "/static" + data;
        const dom = document.createElement("a");
        dom.href = address;
        dom.download = data.substring(1);
        dom.click();
        dom.remove();
      } finally {
        this.exporting = false;
      }
    },
    async exportAlipayByIds() {
      if (this.selectRecord.length == 0) {
        this.$message.error("请先选择要导出的任务");
        return;
      }
      const ids = this.selectRecord.join(",");
      this.exportingAlipay = true;
      try {
        const { data } = await exportAlipayByIds(ids);
        this.selectRecord = [];
        // 地址为 域名+'static' + data
        const address = baseURL + "/static" + data;
        const dom = document.createElement("a");
        dom.href = address;
        dom.download = data.substring(1);
        dom.click();
        dom.remove();
      } finally {
        this.exportingAlipay = false;
      }
    },
    toNewTrial() {
      this.$router.push("/seller/trialCreate");
    },
  },
};
</script>

<style lang="less" scoped>
.status-fixed {
  width: 100%;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 998;
  margin-top: 140px;
  background-color: #fff;
  margin-bottom: 1rem;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
}
.status-bar {
  position: relative;
  padding: 1.5rem 0;
  display: flex;

  &::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 17px;
    width: 100%;
    height: 2px;
    background-color: #57a0ff;
    z-index: 8;
  }
  .status-item {
    cursor: pointer;
    height: 30px;
    text-align: center;

    .status-item_text {
      color: #fff;
      width: 120px;
      height: 35px;
      line-height: 35px;
      margin-right: 7px;
      background-color: #57a0ff;
    }
  }
  .status-item-active {
    .status-item_text {
      position: relative;
      background-color: #fff;
      color: inherit;
      border: 2px solid #57a0ff;
      border-bottom: none;
      &::after {
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #fff;
        z-index: 9;
      }
    }
  }
}
._box {
  margin-bottom: 1rem;
  background-color: #fff;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.25);
}
.p_main {
  padding-top: 100px;

  .filter {
    padding: 1rem;
    ._box;

    .form {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      .fomr-item {
        width: 100%;
      }
      .form-input {
        width: 200px;
      }

      .form-btn {
        border-radius: 22px;
        width: 80px;
      }
      /deep/ .el-form-item {
        margin-bottom: 0;
      }
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    line-height: 1.5rem;
    ._box;
  }

  // .trial-list {}

  .trial-statistics {
    float: left;
    display: flex;
    padding: 1rem;
    width: 30%;
    color: #57a0ff;
    justify-content: space-between;
    ._box;
  }
}

@media (max-width: 520px) {
  ::-webkit-scrollbar {
    display: none;
  }
  .status-fixed {
    margin-top: 60px;
    margin-bottom: 0;
    box-shadow: none;
    .status-bar {
      display: flex;
      white-space: nowrap;
      overflow: auto;

      &::after {
        content: none;
      }
      .status-item {
        .status-item_text {
          color: #57a0ff;
          margin: 0;
          background: none;
        }
      }
      .status-item-active {
        .status-item_text {
          background: none;
          border: none;

          &::after {
            left: 10%;
            width: 80%;
            background-color: #57a0ff;
          }
        }
      }
    }
  }
  ._box_r {
    box-shadow: none;
  }
  .p_main {
    padding-top: 68px;

    .filter {
      padding: 1rem;
      ._box_r;

      .form {
        grid-template-columns: repeat(1, 1fr);
        grid-column-gap: 0.5rem;
      }
    }

    .info {
      display: none;
    }

    .trial-list {
      overflow: hidden;
    }

    .trial-statistics {
      width: auto;
    }
  }
}
</style>
